















































































































import { debounce } from "lodash";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { isKonsulen, isResiden } from "@/apps/accounts/modules/store";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import { SaveContext } from "@/apps/core/models/formModel";
import UjianFM from "../models/ujianForm";

export default defineComponent({
  name: "UjianListBox",
  props: {
    ujian: { type: Object, required: true },
    verifikasi: String,
  },
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
  },
  setup(props) {
    const PICS_LIMIT = 5;
    const pengujiCount = props.ujian.pengujiSet.length;
    const pengujiArr = props.ujian.pengujiSet.slice(0, PICS_LIMIT);
    const form = new UjianFM(["hasil"], ["nilai"]);
    const formRef = reactive(form);
    const instance = formRef.instance;

    Object.assign(formRef.instance, props.ujian);
    Object.assign(formRef.initState, form.instance);
    watch(instance, () => {
      if (!formRef.validity.edited) {
        formRef.setEdited(true);
      }
    });

    const updateNilai = debounce((value) => {
      formRef.validate(value);
      if (formRef.getIsValid().value) {
        formRef.save(SaveContext.Update, false);
      }
    }, 1000);
    formRef.resetErrorMap();

    return {
      // Data
      detailUrl: "/ujian",
      pengujiArr,
      pengujiCount,
      PICS_LIMIT,
      errorMap: formRef.errorMap, // reactive
      instance,

      // Computed
      isKonsulen,
      isResiden,

      // Method
      toUserDateFormat,
      updateNilai,
    };
  },
});
